import { Routes } from '@angular/router';
import { LoginPageComponent } from './pages/login/login-page.component';
import { isLoggedInGuard } from '@core/guards/is-logged-in.guard';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/admin/admin.routes').then(m => m.ADMIN_ROUTES),
    canActivate: [isLoggedInGuard],
  },
  {
    path: 'login',
    component: LoginPageComponent,
    canActivate: [isLoggedInGuard],
  },
  {
    path: 'login/:resetCode',
    component: LoginPageComponent,
  },
  {
    path: '**',
    redirectTo: ''
  }
];
