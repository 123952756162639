import { HttpInterceptorFn } from '@angular/common/http';
import { LoginService } from '../services/auth/login.service';
import { inject } from '@angular/core';
import { catchError, switchMap, throwError } from 'rxjs';
import { LocalStorageService } from '@core/services/auth/local-storage.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {

  if (req.headers.has('X-Skip-Interceptor')) {
    return next(req);
  }

  const loginService = inject(LoginService);
  const localStorageService = inject(LocalStorageService);

  const authToken = localStorageService.getAuthToken();

  const authReq = req.clone({
    setHeaders: {
      Authorization: `Bearer ${authToken}`
    }
  });

  return next(authReq).pipe(
    catchError((err) => {

      if (err.status !== 403) {
        return throwError(() => err);
      }

      return loginService.refreshToken().pipe(
        switchMap((res) => {

          localStorage.setItem('FinwaveAuthToken', res.token);
          localStorage.setItem('FinwaveRefreshToken', res.refresh_token);

          const newAuthReq = req.clone({
            setHeaders: {
              Authorization: `Bearer ${res.token}`,
            },
          });

          return next(newAuthReq);
        }),
        catchError((refreshErr) => {

          loginService.logout();

          return throwError(() => refreshErr);
        })
      );
    })
  );
};
