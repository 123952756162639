import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  inject,
  input,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ILoginForm } from '@core/models/auth/ILoginForm.interface';
import { IRequestCodeForm } from '@core/models/auth/IRequestCodeForm.interface';
import { IResetPassForm } from '@core/models/auth/IResetPassForm.interface';
import { LoginService } from '@core/services/auth/login.service';
import { ResetPassService } from '@core/services/auth/reset-pass.service';
import { InputTextComponent } from '@shared/components/input-text/input-text.component';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { catchError, map, throwError } from 'rxjs';
import { CustomValidators } from '@shared/helpers/customValidators';
import { TranslateModule } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-page',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextComponent,
    ModalComponent,
    TranslateModule,
  ],
  templateUrl: './login-page.component.html',
  styleUrl: './login-page.component.scss',
})
export class LoginPageComponent implements AfterViewInit {
  //TODO: CUANDO GOOGLE AUTOCOMPLETA LOS INPUTS DEL FORMULARIO LOGIN, SE SUPERPONEN LA CONTRASEÑA INVISIBLE CON LA VISBILE, ARREGLAR!

  public resetCode = input<string | null>(null);

  @ViewChild('resetModal') resetModal!: ModalComponent;

  private loginService = inject(LoginService);
  private resetPassService = inject(ResetPassService);
  private router = inject(Router);

  public invalidCredentials = false;

  public loginForm = new FormGroup<ILoginForm>({
    username: new FormControl<string>('', {
      validators: [Validators.required, Validators.email],
    }),
    password: new FormControl<string>('', {
      validators: [Validators.required],
    }),
  });

  public requestCodeForm = new FormGroup<IRequestCodeForm>({
    email: new FormControl('', {
      validators: [Validators.required, Validators.email],
    }),
  });

  public resetPassForm = new FormGroup<IResetPassForm>(
    {
      newPass: new FormControl<string | null>(null, {
        validators: [
          Validators.required,
          Validators.minLength(8),
          CustomValidators.passwordRequirementsValidator,
        ],
      }),
      confirmPass: new FormControl<string | null>(null, {
        validators: [Validators.required],
      }),
    },
    { validators: CustomValidators.passwordMatchValidator }
  );

  constructor() {}

  ngAfterViewInit() {
    if (this.resetCode()) {
      this.resetModal.openDialog();
    }
  }

  async logIn() {
    this.invalidCredentials = false;

    this.loginService
      .getTokens({
        username: this.loginForm.value.username!,
        password: this.loginForm.value.password!,
      })
      .pipe(
        map((response) => {
          this.loginService.setUserInfo(response.token, response.refresh_token);
          this.router.navigate(['/dashboard']);
        }),
        catchError((error) => {
          this.invalidCredentials = true;
          return throwError(() => error);
        })
      )
      .subscribe();
  }

  async requestCode() {
    this.resetPassService
      .requestResetCode(this.requestCodeForm.value.email!)
      .pipe(
        map((response) => console.log(response.status)),
        catchError((error) => throwError(() => error))
      )
      .subscribe();
  }

  async resetPass() {
    this.resetPassService
      .resetPassword(this.resetCode()!, this.resetPassForm.value.newPass!)
      .pipe(
        map((response) => console.log(response.status)),
        catchError((error) => throwError(() => error))
      )
      .subscribe();
  }
}

// async logIn() {
//   this.loginStore.login({
//     username: this.loginForm.value.username!,
//     password: this.loginForm.value.password!,
//   });
// }
