import { AbstractControl, ValidationErrors } from "@angular/forms";

export class CustomValidators {

  static passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
    const newPass = control.get('newPass')?.value;
    const confirmPass = control.get('confirmPass')?.value;

    return newPass === confirmPass ? null : { passwordMismatch: true };
  }

  static passwordRequirementsValidator(
    control: AbstractControl
  ): ValidationErrors | null {
    const value: string = control.value || '';

    const hasAtLeastTwoLetters = /[a-zA-Z].*[a-zA-Z]/.test(value);
    const hasAtLeastTwoNumbers = /\d.*\d/.test(value);
    const hasAtLeastOneUpperCase = /[A-Z]/.test(value);

    if (hasAtLeastTwoLetters && hasAtLeastTwoNumbers && hasAtLeastOneUpperCase) {
      return null;
    } else {
      return {
        passwordRequirements: {
          hasAtLeastTwoLetters,
          hasAtLeastTwoNumbers,
          hasAtLeastOneUpperCase,
        }
      };
    }
  }
}
