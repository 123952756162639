<main>
  <header>
    <h1>{{ 'login.login-page.admin-portal' | translate }}</h1>
  </header>
  <section>
    <form [formGroup]="loginForm" (ngSubmit)="logIn()" class="loginForm">
      <app-input-text
        formControlName="username"
        [label]="'login.login-page.username' | translate"
        [placeholder]="'login.login-page.username-placeholder' | translate"
      />
      <app-input-text
        formControlName="password"
        [label]="'login.login-page.password' | translate"
        placeholder="******"
        isPassword
      />
      <button
        type="submit"
        class="main_button"
        [class.disabled]="loginForm.invalid"
        [disabled]="loginForm.invalid"
      >
        {{ 'login.login-page.enter' | translate }}
      </button>
    </form>
    @if (invalidCredentials) {
    <article>
      <button (click)="invalidCredentials = false">
        <img src="assets/icons/close.svg" alt="" />
      </button>
      <form
        [formGroup]="requestCodeForm"
        class="requestCodeForm"
        (ngSubmit)="requestCode()"
      >
        <div>
          <i><img src="assets/icons/attention.svg" alt="" /></i>
          <div>
            <h2>{{ 'login.login-page.error-title' | translate }}</h2>
            <span>{{ 'login.login-page.invalid-credentials' | translate }}</span>
            <span>{{ 'login.login-page.change-credentials' | translate }}</span>
            <app-input-text
              formControlName="email"
              [label]="'login.login-page.email' | translate"
              [placeholder]="'login.login-page.email-placeholder' | translate"
            />
          </div>
        </div>

        <button
          type="submit"
          class="main_button no-top-rounded"
          [ngClass]="{ hidden: requestCodeForm.invalid }"
        >
          {{ 'login.login-page.send' | translate }}
        </button>
      </form>
    </article>
    } @else {
    <button (click)="requestModal.openDialog()">
      <span>{{ 'login.login-page.forgot-password' | translate }}</span>
    </button>
    }
  </section>
  <footer>
    <a href="">{{ 'login.login-page.terms-and-conditions' | translate }}</a>
    <span> - </span>
    <a href="">{{ 'login.login-page.privacy-policy' | translate }}</a>
  </footer>
</main>

<app-modal #requestModal id="requestModal">
  <form [formGroup]="requestCodeForm" (ngSubmit)="requestCode(); requestModal.closeDialog()">
    <div class="content">
      <h2>{{ 'login.login-page.recover-password' | translate }}</h2>
      <div class="separator"></div>
      <span>{{ 'login.login-page.recover-password-instructions' | translate }}</span>
      <app-input-text [placeholder]="'login.login-page.email-placeholder' | translate" formControlName="email"/>
    </div>
    <button type="submit" class="main_button no-top-rounded" [class.disabled]="requestCodeForm.invalid"
    [disabled]="requestCodeForm.invalid">{{ 'login.login-page.send' | translate }}</button>
  </form>
</app-modal>

<app-modal #resetModal id="resetModal">
  <form [formGroup]="resetPassForm" (ngSubmit)="resetPass(); resetModal.closeDialog()">
    <div class="content">
      <h2>{{ 'login.login-page.change-password' | translate }}</h2>
      <div class="separator"></div>
      <span>{{ 'login.login-page.new-password-instructions' | translate }}</span>
      <ul>
        <li>{{ 'login.login-page.password-rule-1' | translate }}</li>
        <li>{{ 'login.login-page.password-rule-2' | translate }}</li>
        <li>{{ 'login.login-page.password-rule-3' | translate }}</li>
        <li>{{ 'login.login-page.password-rule-4' | translate }}</li>
      </ul>
      <app-input-text placeholder="******" formControlName="newPass" [label]="'login.login-page.new-password' | translate" isPassword/>
      <app-input-text placeholder="******" formControlName="confirmPass" [label]="'login.login-page.confirm-password' | translate" isPassword/>
    </div>
    <button type="submit" class="main_button no-top-rounded" [class.disabled]="resetPassForm.invalid"
      [disabled]="resetPassForm.invalid">{{ 'login.login-page.send' | translate }}</button>
  </form>
</app-modal>
