import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@envs/environment';

@Injectable({
  providedIn: 'root'
})
export class ResetPassService {

  private httpClient = inject(HttpClient);

  constructor() { }

  requestResetCode(email: string) {
    // return firstValueFrom(this.httpClient.post<void>(environment.API_BASE_URL + 'users/request-password-reset' + '?email=' + email, { }, { observe: 'response'}));
    return this.httpClient.post<void>(environment.API_BASE_URL + 'users/request-password-reset' + '?email=' + email, { }, { observe: 'response'})
  }

  resetPassword(code: string, password: string) {
    // return firstValueFrom(this.httpClient.post<void>(environment.API_BASE_URL + 'users/reset-password-code', { code, password }, { observe: 'response'}));
    return this.httpClient.post<void>(environment.API_BASE_URL + 'users/reset-password-code', { code, password }, { observe: 'response'});
  }
}
