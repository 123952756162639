import { inject, Injectable } from '@angular/core';
import { LoginService } from '../auth/login.service';
// import { take } from 'rxjs';
import { LoginStore } from '../../store/login.store';

@Injectable({
  providedIn: 'root'
})
export class InitializeAppService {

  private loginService = inject(LoginService)
  private loginStore = inject(LoginStore)

  constructor() { }

  initializeApp(): Promise<void> {
    return new Promise((resolve) => {
      const refresh_token = localStorage.getItem('FinwaveRefreshToken');
      const token = localStorage.getItem('FinwaveAuthToken');
      if (token && token.length > 0 && refresh_token && refresh_token.length > 0) {
        this.loginService.setUserInfo(token, refresh_token);
        resolve();
      } else {
        resolve();
      }
    });
  }

  // initializeApp(): Promise<void> {
  //   return new Promise((resolve, reject) => {
  //     const refresh_token = localStorage.getItem('FinwaveRefreshToken');
  //     if (refresh_token && refresh_token.length > 0) {
  //       this.loginService.refreshToken().pipe(take(1)).subscribe({
  //         next: (res) => {
  //           this.loginService.setUserInfo(res.token, res.refresh_token);
  //           resolve();
  //         },
  //         error: (err) => reject(err)
  //       });
  //     } else {
  //       resolve();
  //     }
  //   });
  // }
}
