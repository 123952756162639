import { Injectable, Inject, PLATFORM_ID, InjectionToken, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformBrowser } from '@angular/common';
import { LocalStorageService } from '../auth/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  defaultLang = 'es';
  localStorageService = inject(LocalStorageService);

  constructor(
    private translateService: TranslateService,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<object>
  ) {
    if (isPlatformBrowser(this.platformId)) {
      const savedLang = this.localStorageService.getLang();
      if (savedLang) {
        this.defaultLang = savedLang;
      }
      this.translateService.setDefaultLang(this.defaultLang);
      this.translateService.use(this.defaultLang);
    }
  }

  changeLang(lang: string) {
    this.translateService.use(lang);
    if (isPlatformBrowser(this.platformId)) {
      this.localStorageService.setLang(lang);
    }
  }
}
